import React from 'react'

import './Sessions.css'

class Sessions extends React.Component {
  render() {
    return (
      <div>
        <div className="jumbotron sessions-jumbotron sessions-cover" style={{ backgroundColor: "black" }}>
          <div className="container">
            <a href="https://api.fitness-academie.fr/widget/survey/HOX05JYmqxtCun4jikaX">
              Réservez votre rendez-vous pour un bilan de forme
            </a>
            <iframe src="https://api.fitness-academie.fr/widget/survey/HOX05JYmqxtCun4jikaX"
              style={{border:"none", width:"100%"}}
              scrolling="no" id="HOX05JYmqxtCun4jikaX"
              title="Contact web">
            </iframe>    
            <img src="/static/images/sessions/planning.png" className="img-fluid" />
          </div>
          <div className="container mt-5">
            <img className="img-fluid" style={{ width: 120 }} src="/static/images/logos/logo-full-white-transparent.png" /><br />
            N'hésitez plus, contactez nous : +33 6 46 08 55 66<br />
            Ou passez tout simplement nous voir : 16 Avenue Louis de Broglie, 33600 Pessac
          </div>
        </div>
      </div>
    );
  }
}
export default Sessions;